import React from "react";


const CardContacto = () => {
  return (
<div className="divContacto">
    <h2 className="tituloAyudamos mb-4" >Te ayudamos en lo que quieras</h2>
    <p className="tituloContacto mb-0" style={{fontWeight:'400'}}>Las Estacas</p>
    <a href="tel:+7341202916" style={{fontSize:'16px'}} className="linksContacto mb-1">(734) 120 2916 al 19</a>
    <p className="labelNormal mb-3">Lunes a domingo: 8 am a 6 pm </p>
    
    <p className="tituloContacto mb-0" style={{fontWeight:'400'}}>Oficinas CDMX</p>
    <a href="tel:+5530045300" style={{fontSize:'16px'}} className="linksContacto mb-1">(55) 3004 5300 al 02</a>
    <p className="labelNormal mb-3">Lunes a viernes: 9 am a 6 pm
Sábado: 9 am a 2 pm</p>

    <p className="tituloContacto mb-0" style={{fontWeight:'400'}}>WhatsApp</p>
    <a href="https://api.whatsapp.com/send?phone=7341142287" style={{fontSize:'16px'}} className="linksContacto mb-1">(734) 114 2287</a>
    <p className="tituloContacto mb-0" style={{fontWeight:'400'}}>WhatsApp Hotel</p>
    <a href="https://api.whatsapp.com/send?phone=7341142287" style={{fontSize:'16px'}} className="linksContacto mb-1">(55) 2112 9649</a>
    <p className="tituloContacto mb-0" style={{fontWeight:'400'}}>WhatsApp Glamping</p>
    <a href="https://api.whatsapp.com/send?phone=7341142287" style={{fontSize:'16px'}} className="linksContacto mb-1">(734) 160 0906</a>
    <p className="labelNormal mb-3">Lunes a domingo: 8 am a 6 pm </p>

<h4 className="tituloContacto mb-2" style={{fontWeight:'400'}}>Más informes</h4>
<p className="labelNormal mb-0">Información general</p>
<div  style={{marginBottom:'16px'}}>
<a href="mailto:informes@lasestacas.com" style={{fontSize:'16px',marginBottom:'16px'}} className="linksContacto ">informes@lasestacas.com</a>
</div>
<p className="labelNormal mb-0">Grupos empresariales y sociales</p>
<div  style={{marginBottom:'16px'}}>
<a href="mailto:ventas@lasestacas.com" style={{fontSize:'16px' ,marginBottom:'16px'}}  className="linksContacto ">ventas@lasestacas.com</a>
</div>
<p className="labelNormal mb-0">Hospedaje</p>
<div  style={{marginBottom:'16px'}}>

<a href="mailto:reservaciones@lasestacas.com" style={{fontSize:'16px',marginBottom:'16px'}} className="linksContacto">reservaciones@lasestacas.com</a>
    </div>
</div>
  );
};

export default CardContacto;