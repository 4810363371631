/*import React from "react";
import CardBlog from "./card_blog";


const  BlogsCards = ({id,data}) => {
   
        //console.log(datos)
   
    
  return (
      <div>
          <div className={'text-center'}>
          <h2 className={'tituloInteres'}>Blog Las Estacas</h2>
          <p>Te ponemos al tanto de lo que ocurre dentro del paraíso.</p>
          </div>
       <div className={'d-md-flex d-sm-block mx-lg-5 mx-sm-0 '}>
           <CardBlog/>
           <CardBlog/>
           <CardBlog/>
       </div>
       </div>
    
  );
};

export default BlogsCards;
*/

import React, { Component } from "react";
import Slider from "react-slick";
import CardBlog from "./card_blog";
import $ from 'jquery'

export default class BlogsCards extends Component {

  componentDidMount(){
    

  }


  render() {
    setTimeout(() => {
      var width= window.innerWidth 
console.log(width) 
if (width<=800&&width>=500) {
  

      console.log(document.getElementsByClassName('slick-slide'))
      //$('.slick-slide').css('width','500px')
     // $('.slick-slide').css('width','500px')
    //$('.slick-slide').css('margin-left','-20px')
}if(width>800 && width<1100){
  console.log(  $('.slick-slide'))

 // $('.anchoComplementa').css('width','unset')

  //$('.slick-slide').css('width','500px')
  //$('.slick-slide').css('margin-left','15px')

  //$('.slick-active').css('width','300px')
  //$('.slick-cloned').css('width','300px')
  $('.anchoComplementa').css('width','unset')

}if(width<400){
  //$('.slick-slide').css('width','290px')
  //$('.slick-slide').css('margin-left','30px')
  var element = document.querySelectorAll('.prueba  .slick-slide')
  for (let index = 0; index < element.length; index++) {
    //element[index].style.width='212px'
   // element[index].style.marginLeft='15px'
   // element[index].style.marginRight='15px'

    console.log(element[index])
    
  }
  /*var element = document.querySelectorAll('.prueba  .slick-active')
  for (let index = 0; index < element.length; index++) {
    element[index].style.width='430px'
    element[index].style.marginLeft='0px'
    //element[index].style.marginRight='20px'

    console.log(element[index])
    
  }*/
}
/*if (width<500) {
  //$('.slick-slide').css('width','350px')
 // $('.slick-slide').css('margin-left','-20px')
}*/
    }, 1000);
    console.log(this.props.data)
    let datos =this.props.data
    const settings = {
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 3,
        centerMode: true,
        slidesToScroll: 1,
        speed: 500,
        className:"prueba",
        responsive: [
            {
              breakpoint: 1040,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                centerMode: true,
                centerPadding: '300px'

              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite:true,
                //centerMode: true,
                width: "200px",
                centerPadding: '170px'
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite:true,
                centerMode: true,
                centerPadding: '50px'

              }
            },
            {
              breakpoint: 400,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite:true,
                centerMode: true,
                centerPadding: '28px'
              }
            },
            {
              breakpoint: 330,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite:true,
                centerMode: true,
                centerPadding: '8px'
              }
            }
        ]
      };
    return (
      <div className={'mt-5 anchoComplementa'}>
        <div className={'text-center'}>
        <h2 className={'echaVistazo'}>Blog Las Estacas</h2>
          <p>Te ponemos al tanto de lo que ocurre dentro del paraíso.</p>
        </div>
        <Slider {...settings}>
        <div className={'d-flex justify-content-center '}>
        <CardBlog data={datos[0]}/>
         </div>
          <div className={'d-flex justify-content-center '} >
          <CardBlog data={datos[1]}/>
          </div>
          <div className={'d-flex justify-content-center '}>
          <CardBlog data={datos[2]}/>
          </div>
        </Slider>
      </div>
    );
  }
}