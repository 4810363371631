import React from "react";


const CardContactoMobile = () => {
  return (
<div className="row justify-content-center mt-0">
<h2 className="tituloAyudamos text-center mb-4 mt-3" >Te ayudamos en lo que quieras</h2>

<div className="col-12 d-flex justify-content-evenly">


    <div className="col-4 ">

   
    <p className="tituloContacto mb-0" style={{fontWeight:'400'}}>Las Estacas</p>
    <a href="tel:+7341202916" style={{fontSize:'16px'}} className="linksContacto mb-1">(734) 120 2916 al 19</a>
    
    <p className="labelNormal mb-3">Lunes a domingo: 8 am a 6 pm </p>
    
    <p className="tituloContacto mb-0" style={{fontWeight:'400'}}>Oficinas CDMX</p>
    

    <a href="tel:+5530045300" style={{fontSize:'16px'}} className="linksContacto mb-1">(55) 3004 5300 al 02</a>
    <p className="labelNormal mb-3">Lunes a viernes: 9 am a 6 pm
Sábado: 9 am a 2 pm</p>
</div>
<div className="col-4 ">

<p className="tituloContacto mb-0" style={{fontWeight:'400'}}>WhatsApp</p>
    <a href="https://api.whatsapp.com/send?phone=7341142287" style={{fontSize:'16px'}} className="linksContacto mb-1">734 114 2287</a>
    <p className="labelNormal mb-3">Lunes a domingo: 8 am a 6 pm </p>

<h4 className="tituloContacto mb-0" style={{fontWeight:'400'}}>Más informes</h4>
<p className="labelNormal mb-0">Información general</p>
<a href="mailto:informes@lasestacas.com" style={{fontSize:'16px'}} className="linksContacto mb-2">informes@lasestacas.com</a>

<p className="labelNormal mb-0">Grupos empresariales y sociales</p>
<a href="mailto:ventas@lasestacas.com" style={{fontSize:'16px'}}  className="linksContacto mb-2">ventas@lasestacas.com</a>

<p className="labelNormal mb-0">Hospedaje</p>
<a href="mailto:reservaciones@lasestacas.com" style={{fontSize:'16px'}} className="linksContacto mb-2">reservaciones@lasestacas.com</a>
</div> 
</div> 
</div>
  );
};

export default CardContactoMobile;